.product-banner {
  color: #fff;
  position: relative;
  top: -20px;
  z-index: 10;
  background-image: url("../../../assets/images/product-banner.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  width: 100%;
  padding-bottom: 200px;
  padding-top: 30px;
  height: auto;
}
.ar a,
.en a {
  position: relative;
  top: 50px;
  width: fit-content !important;
}
.ar .description,
.ar span,
.ar button {
  padding-right: 136px;
}
.en .description,
.en span,
.en button {
  padding-left: 136px;
}
.product-banner h2 {
  margin-top: 80px;
  font-size: 44px;
  font-weight: 700;
  line-height: 36px;
  padding: 0 136px;
  margin-bottom: 43px;
}
.product-banner p {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  width: 90%;
  display: inline-block;
}
.back {
  display: flex;
  align-items: center;
  color: #fff;
  row-gap: 16px;
  position: relative;
  top: 70px;
}
.back p {
  width: auto !important;
}
@media (max-width: 1279px) {
  .product-banner img {
    height: 400px;
  }
  .ar .description,
  .ar span {
    padding-right: 24px;
    width: 90%;
  }
  .en .description,
  .en span {
    width: 90%;
    padding-left: 24px;
  }
  .ar button {
    padding-right: 24px;
  }
  .en button {
    padding-left: 24px;
  }
  .product-banner h2 {
    margin-top: 100px;
    font-weight: 700;
    padding: 0 24px;
    margin-bottom: 43px;
  }
  .product-banner + p {
    padding: 50px 24px 0 24px;
  }
}
@media (max-width: 1024px) {
  .product-banner img {
    height: auto;
  }
}
@media (max-width: 678px) {
  .ar img {
    height: auto;
  }
  .en img {
    height: auto;
  }
  .product-banner h2 {
    margin-top: 100px;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
  }
  .product-banner + p {
    padding: 0px 24px 0 24px;
  }
}
@media (max-width: 413px) {
  .ar img {
    height: auto;
  }
  .en img {
    height: auto;
  }
  .ar .description,
  .ar span {
    padding-right: 3%;
    width: 95%;
    font-size: 14px;
  }
  .en .description,
  .en span {
    width: 95%;
    padding-left: 3%;
    font-size: 14px;
  }
  .ar button {
    padding-right: 3%;
    font-size: 14px;
  }
  .en button {
    padding-left: 3%;
    font-size: 14px;
  }
  .ar h2 {
    padding-right: 3%;
    width: 95%;
  }
  .en h2 {
    width: 95%;
    padding-left: 3%;
  }
  .product-banner h2 {
    margin-top: 100px;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
  }
}
