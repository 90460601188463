.projects-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 41px;
  row-gap: 30px;
  padding: 62px 8% 125px;
}
.project-card {
  position: relative;
  width: 100%;
  height: 304px;
  border: 1px solid #c6c6c6;
}
.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(304px - 55px);
}

.center-image img {
  height: 160px;
  width: auto;
  object-fit: contain;
}
.display-project {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  cursor: pointer;
  gap: 6px;
}
.display-project h5 {
  color: #000;
  font-size: 15px;
  font-weight: 300;
  line-height: 30px;
  position: relative;
}

/* active card */
.active-card {
  grid-column: span 3;
  width: 100%;
  border: 1px solid #c6c6c6;
  background: #fff;
  position: relative;
  padding: 53px 43px 40px 20px;
  transform: scale(0.4);
  opacity: 0;
  transition: all 0.5s ease;
}
.active-card .right-text {
  width: 75%;
}
.active-card .company-logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
  position: absolute;
  top: 93px;
}
.frameworks {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.frameworks .frmaework-img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.active-card h3 {
  color: #000;
  font-size: 17px;
  font-weight: 700;
  line-height: 30px;
}
.active-card p {
  color: #6b7280;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  margin: 18px 0;
}
.active-card p:last-child {
  margin-bottom: 29px;
}
.active-card .link,
.active-card .link p {
  color: #fd5d63;
  font-size: 19px;
  line-height: 30px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
}

.active-card.active {
  transform: scale(1);
  opacity: 1;
}

@media (max-width: 1198px) {
  .projects-wrapper {
    grid-template-columns: auto auto;
  }
  .active-card {
    grid-column: span 2;
    padding: 30px;
  }
  .company-logo {
    position: relative !important;
    top: 0 !important;
    margin-bottom: 20px;
  }
  .active-card .right-text {
    width: 100%;
  }
}
@media (max-width: 740px) {
  .projects-wrapper {
    grid-template-columns: auto;
  }
  .center-image img {
    width: auto;
  }
  .active-card {
    grid-column: span 1;
  }
}
