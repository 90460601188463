.form-par {
  padding: 103px 135px;
  background: #f7f7f7;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 52px;
}
.form-par h3 {
  color: #000;
  font-size: 45px;
  font-weight: 700;
  line-height: 48px;
}
.form-detail p {
  color: #6b7280;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  width: 529px;
  margin-bottom: 87px;
}

.form-detail h4 {
  color: #000;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 15px;
}
.social {
  display: flex;
  align-items: center;
  gap: 34px;
}
.phone,
.email {
  display: flex;
  align-items: center;
  gap: 13px;
}
.phone span,
.email span {
  color: #6b7280;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  cursor: pointer;
}
.contact-form {
  width: 588px;
  flex-shrink: 0;
  background-color: #fff;
  padding: 62px 38px 41px;
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.contact-form input {
  border: none;
  border-bottom: 1px solid rgba(16, 22, 45, 0.24);
  padding-bottom: 24px;
  font-size: 20px;
  color: #000;
  font-weight: 300;
}
.contact-form input:focus {
  outline: none;
}
.contact-form input::placeholder {
  font-family: "Frutiger LT Arabic", sans-serif;
  font-size: 20px;
  color: #0000009d;
  font-weight: 400;
}
.contact-form button {
  margin-top: calc(68px - 44px);
  border: 3px solid #fd5d63;
  background: #fd5d63;
  padding: 14.66px 35.84px 17px 35px;
  color: white;
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Frutiger LT Arabic", sans-serif;
  cursor: pointer;
  transition: 300ms;
}
.contact-form button:hover {
  border: 3px solid #fd5d63;
  background-color: transparent;
  color: #fd5d63;
}
.contact-form div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 1425px) {
  .form-par {
    padding: 103px 10%;
  }
  .contact {
    flex-direction: column;
  }
  .form-par h3 {
    margin-bottom: 100px;
    text-align: center;
  }
  .form-detail p {
    width: auto;
  }
  .contact-form {
    width: 100%;
  }
  .contact-form input {
    width: 100%;
  }
}
@media (max-width: 681px) {
  .contact-form {
    width: 100%;
  }
  .contact-form div {
    display: flex;
    flex-direction: column;
    gap: 44px;
  }
  .form-par {
    padding: 103px 4%;
  }
  .social {
    flex-direction: column;
    align-items: start;
    width: 100%;
    align-items: start;
  }
}
@media (min-width: 1627px) {
  .contact-form {
    width: 700px;
  }
  .contact-form input {
    width: 100%;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
