.down-arrow {
  position: relative;
  animation-name: arrow;
  animation-direction: alternate-reverse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.svg {
  position: relative;
  animation-name: svg;
  animation-direction: alternate-reverse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes arrow {
  0% {
    top: 10px;
  }
  100% {
    top: 0;
  }
}
@keyframes svg {
  0% {
    opacity: 0.2;
  }
  100% {
    bottom: 1;
  }
}
