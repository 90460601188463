.our-partners {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 101px 0;
  padding: 0 4%;
}
.our-partners div {
  display: flex;
  width: 220px;
  height: 150px;
  padding: 60px 62.912px 60px 63px;
  justify-content: center;
  align-items: center;
  border: 1px solid #c6c6c6;
  background: #fff;
}
.our-partners img {
  width: 86px;
  height: 86px;
  object-fit: contain;
}
@media (max-width: 1247px) {
  .our-partners {
    grid-template-columns: auto auto auto;
  }
}
@media (max-width: 837px) {
  .our-partners {
    grid-template-columns: auto auto;
    gap: 30px;
    margin: 70px 0;
  }
}
@media (max-width: 542px) {
  .our-partners {
    gap: 20px;
  }
  .our-partners div {
    width: 100%;
  }
  .our-partners img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
}
@media (max-width: 488px) {
  .our-partners {
    gap: 14px;
  }
  .our-partners div {
    width: 150px;
    height: 100px;
  }
  .our-partners img {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
}
